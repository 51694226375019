import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import Container from '../components/container'
import ContentBlockSmall from '../components/content-block-small'
import ContentBlockSecondary from '../components/content-block-secondary'
import IconTrust from '../assets/images/trust-icon.svg'
import ContentBannerWithCTA from '../components/content-banner-with-cta'
import ContentBlockLargeWithGallery from '../components/content-block-large-gallery'
import { UseCaseBlock, UseCasesContainer, UseCaseTeaser } from '../components/content-use-cases'
import HomeAnimation from '../images/home-hero-animation.gif'
import { heroContainer, contentBlock } from './index.module.scss'
import ActionMetaInfo from '../components/content-action-meta-info'
import ContentBannerEnterprise from '../components/content-banner-enterprise'

const IndexPage = ({ data, location }) => (
  <MainContainer>
    <SEO title="Home" />
    <Container>
      {/* <ContentHeroNew
        headline="Boost productivity with a Digital Hub for Microsoft 365"
        description="Connect all your apps with Microsoft 365 and optimize your daily workflow "
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup&utm_content=home"
        demoButton
      /> */}

      <div className={heroContainer}>
        <h1 className="hero-headline">Boost productivity with a Digital Hub for Microsoft 365</h1>
        <span className="hero-description">Connect all your apps with Microsoft 365 and optimize your daily workflow</span>
        <div className="action-container">
          <a className="cta" href={'https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup-basaas-2022&utm_content=home'} target="_blank" rel={'noreferrer'}>
            Get started – It's free!
          </a>

          <Link className="secondary" to="/demo">
            Book a Demo
          </Link>

          <ActionMetaInfo style={{ marginTop: '16px' }} />
        </div>

        <img className="hero-media" src={HomeAnimation} alt="Boost productivity with a Digital Hub for Microsoft 365" />
      </div>
    </Container>

    <Container style={{ padding: '32px 0 80px' }}>
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px', color: 'var(--neutral-500)' }}>
        <IconTrust style={{ marginRight: '12px' }} />{' '}
        <span>
          Basaas is trusted by <span style={{ display: 'inline-block', fontWeight: '600' }}>{data.site.siteMetadata.teamsCount}+ teams</span> worldwide
        </span>
      </span>
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        title="Everything in one place"
        // highlightColor="#f00"
        content={[
          {
            linkTitle: 'MyDay',
            title: 'Manage your inbox overload',
            description: 'Manage your entire daily business in one place. See all notifications at a glance and organize to dos and priorities with ease.',
            media: <StaticImage src="../assets/images/basaas-2022-myday.png" alt="Manage your inbox overload" placeholder="blurred" />,
          },
          {
            linkTitle: 'Tasks',
            title: 'Unite all tasks in one solution',
            description: 'Connect Microsoft To Do or Planner with Asana, MeisterTask and more to a unified task solution. Create tasks everywhere and assign tasks to teammates.',
            media: <StaticImage src="../assets/images/basaas-2022-tasks.png" alt="Unite all tasks in one solution" placeholder="blurred" />,
          },
          {
            linkTitle: 'Documents',
            title: 'Simply find all documents',
            description: 'Connect all your OneDrive accounts to a unified document solution and never lose valuable time through unnecessary searching.',
            media: <StaticImage src="../assets/images/basaas-2022-documents.png" alt="Simply find all documents" placeholder="blurred" />,
          },
          {
            linkTitle: 'Search',
            title: 'Find everything cross app',
            description: 'Find information faster with an overarching search which looks up your connected apps like Outlook, OneDrive, Sharepoint etc.',
            media: <StaticImage src="../assets/images/basaas-2022-search.png" alt="Find everything cross app" placeholder="blurred" />,
          },
        ]}
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        title="Browser wide productivity boost"
        highlightColor="#08CF65"
        content={[
          {
            linkTitle: 'Apps',
            title: 'All your apps in one solution',
            description: 'Organize all your apps and bookmarks with ease and build your personnel digital hub based on Microsoft 365. Find all company apps in one central place.',
            media: <StaticImage src="../assets/images/basaas-2022-apps.png" alt="All your apps in one solution" placeholder="blurred" />,
          },
          {
            linkTitle: 'Sidebar',
            title: 'Favorite apps omnipresent',
            description: 'Use your sidebar everywhere in your browser, no matter which website you’re on. Get notifications from your favorite apps and launch them with just one click.',
            media: <StaticImage src="../assets/images/basaas-2022-sidebar.png" alt="Favorite apps omnipresent" placeholder="blurred" />,
          },
          {
            linkTitle: 'Popup',
            title: 'Switch your apps with ease',
            description: 'With the handy popup, you can easily find and switch all your apps with one click and save a lot of time. Use the search and find all your apps in the blink of an eye.',
            media: <StaticImage src="../assets/images/basaas-2022-popup.png" alt="Switch your apps with ease" placeholder="blurred" />,
          },
          {
            linkTitle: 'Shortcuts',
            title: 'Work cross app on any website',
            description: 'No matter on which website - create new tasks e.g. for Microsoft To Do anywhere in your browser or launch apps with a shortcut or a right-click.',
            media: <StaticImage src="../assets/images/basaas-2022-shortcut-create-task.png" alt="Work cross app on any website" placeholder="blurred" />,
          },
        ]}
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        title="Built for teams of every size"
        highlightColor="#FF4F4F"
        content={[
          {
            linkTitle: 'Platform',
            title: 'Tailor-made best of breed solution',
            description: 'Create an overarching enterprise solution and integrate all your existing apps and Microsoft 365 into one digital hub.',
            media: <StaticImage src="../assets/images/basaas-2022-groups.png" alt="Tailor-made best of breed solution" placeholder="blurred" />,
          },
          {
            linkTitle: 'Apps',
            title: 'Share any app or link easily',
            description: 'Distribute your apps for teams, departments, or individual users. Roll-out your company apps or share contents for all colleagues.',
            media: <StaticImage src="../assets/images/basaas-2022-custom-apps.png" alt="Share any app or link easily" placeholder="blurred" />,
          },
          {
            linkTitle: 'Analytics',
            title: 'Analyze and optimize SaaS usage',
            description: 'Gain meaningful insights into your company’s app usage. A centralized app management creates transparency and reduces shadow IT.',
            media: <StaticImage src="../assets/images/basaas-2022-analytics.png" alt="Analyze and optimize SaaS usage" placeholder="blurred" />,
          },
          {
            linkTitle: 'Collaborate',
            title: 'All projects in one solution',
            description: 'Create projects easily to bring your team’s work together. Projects helps you to gain transparency to your team while improving collaboration.',
            media: <StaticImage src="../assets/images/basaas-2022-tasks-projects.png" alt="All projects in one solution" placeholder="blurred" />,
          },
        ]}
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBannerEnterprise />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        title="Work across apps and teams"
        highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Events',
            title: 'Overview of all calendars',
            description: `See your next events from integrated apps in your unified calendar. If it's an online meeting, you can join with just one click.`,
            media: <StaticImage src="../assets/images/basaas-2022-calendar.png" alt="Overview of all calendars" placeholder="blurred" />,
          },
          {
            linkTitle: 'Projects',
            title: 'Assign tasks to teammates',
            description: 'Simply delegate tasks to your colleagues. Keep always the overview of your delegated tasks and get notified about completed tasks.',
            media: <StaticImage src="../assets/images/basaas-2022-tasks-assign.png" alt="Assign tasks to teammates" placeholder="blurred" />,
          },
          {
            linkTitle: 'Contacts',
            title: 'Find all contacts in one place',
            description: 'If your contacts data are spread across your apps you can now find them in a single solution and save precious time on every search.',
            media: <StaticImage src="../assets/images/basaas-2022-contacts.png" alt="Find all contacts in one place" placeholder="blurred" />,
          },
          {
            linkTitle: 'News',
            title: 'Get always the latest news',
            comingSoon: true,
            description: 'Never miss the latest news of your company. Get notified, see relevant information at a glance and find every information with one click.',
            media: <StaticImage src="../assets/images/basaas-2022-news-preview.png" alt="Get always the latest news" placeholder="blurred" />,
          },
        ]}
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '160px 0 80px', marginTop: '160px' }}>
      <h2 style={{ fontSize: '24px', margin: '0 0 40px', fontWeight: 800 }}>With Basaas you can constantly optimize …</h2>
      <UseCaseBlock location={location.pathname} />
    </Container>

    {/* BLOCK SECURITY  */}
    <Container style={{ background: 'var(--base-background)' }}>
      <ContentBlockSecondary
        headline="Enterprise productivity at the highest security level"
        description="The Basaas security concept is based on international best in class standards and EU requirements and guarantees the highest enterprise security level."
        // actionLinkText="Learn more"
        // actionLink="/tasks"
        item_01={
          <ContentBlockSmall
            key="1"
            headline="Fully GDPR compliant"
            description="Basaas is fully comply with European data security regulations including GDPR. For European companies, we guarantee that data storage takes place exclusively in the EU."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../assets/feature-icons/gdpr-compliant.png" alt="Fully GDPR compliant" placeholder="blurred" />}
          />
        }
        item_02={
          <ContentBlockSmall
            key="2"
            headline="Proven enterprise security"
            description="At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../assets/feature-icons/proven-enterprise-security.png" alt="Proven enterprise security" placeholder="blurred" />}
          />
        }
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
